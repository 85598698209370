import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import ListAgents from "@components/agents/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "agents.getAll",
  query: 'is_agent=true&approval_status=approved&approval_status=rejected&approval_status=pending'
}

const AgentListPage = (props) => (
  <>
    <Seo title="Agents List" />
    <div className="main-wrapper">
      <Sidebar menuActive="agents" />
      <ListAgents
        addBtnText="Agent"
        menu="Agents" 
        parentPath="agents" 
        pageType="List"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default AgentListPage;
